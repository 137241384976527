@import "../../styles/variables";

.navigation {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding-right: 2rem;
  text-align: right;
  display: flex;
  flex-direction: column;

  a {
    margin-top: 2.5rem;
    font-size: 1.5rem;
    color: $text;
    text-decoration: none;
    font-variant: small-caps;

    &:hover {
      color: $foreground;
    }
  }

  @media (max-width: 1024px) {
    visibility: none;
  }
}
