.loaderWrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    margin-right: 1rem;
    animation-name: pulse;
    animation-duration: 1s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
}

@keyframes pulse {
  from {
    opacity: 0.5;
    transform: rotate(0);
  }

  to {
    opacity: 1;
    transform: rotate(360deg);
  }
}
