.home {
  display: flex;
  height: 100vh;
  width: 100vw;

  background-image: url("../../assets/vinoth.webp"),
    url("../../assets/vinoth.png");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: right;
  background-origin: border-box;
  background-size: cover;

  animation: fadeIn 2s;

  @media (max-width: 768px) {
    background-size: cover;
    background-position: center;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
