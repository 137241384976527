@import "../../styles/variables";

.name {
  display: flex;
  flex-direction: column;
  margin-left: 10rem;

  position: absolute;
  transform: translateY(-50%);

  .title {
    font-family: "Oswald", sans-serif;
    font-size: 7.5rem;
    span {
      color: $accent;
    }
  }

  .subtitle {
    opacity: 0.75;
    font-size: 2.5rem;
    padding-top: 0.75rem;
    padding-left: 0.75rem;
  }
}

@media (max-width: 600px) {
  .name {
    margin-left: 2.5rem;
    .title {
      font-size: 3.5rem;
    }

    .subtitle {
      font-size: 1rem;
      padding-top: 0.25rem;
      padding-left: 0.5rem;
      line-height: normal;
    }
  }
}
