@import "../../styles/variables";

.social {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99999;

  .icon {
    height: 2rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}
