@import "../../styles/variables";

.burger {
  position: absolute;
  top: 0;
  right: 0;

  background: none;
  border: none;
}

.burgerButton {
  padding: 0.5rem !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.closeButton {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.links {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-left: 2rem;
  overflow: hidden;

  a {
    display: flex;
    margin-top: 1.75rem;
    font-size: 1.25rem;
    color: $foreground;
    text-decoration: none;
    font-variant: small-caps;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

.menu {
  margin: 0;
  padding: 0;
  background-color: #1f2020;
}

.body {
  overflow: hidden;
}
