@import url(https://fonts.googleapis.com/css2?family=Lato&family=Oswald:wght@300&display=swap);
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:"";content:none}table{border-collapse:collapse;border-spacing:0}html{scroll-behavior:smooth}body{margin:0;font-family:"Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#010203;color:snow;overflow-x:hidden}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}

.sidebar_navigation__2FzTC{position:absolute;right:0;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);padding-right:2rem;text-align:right;display:flex;flex-direction:column}.sidebar_navigation__2FzTC a{margin-top:2.5rem;font-size:1.5rem;color:#c8c8c8;text-decoration:none;-webkit-font-feature-settings:"c2sc";font-feature-settings:"c2sc";font-variant:small-caps}.sidebar_navigation__2FzTC a:hover{color:snow}@media (max-width: 1024px){.sidebar_navigation__2FzTC{visibility:none}}

.burger_burger__3GRam{position:absolute;top:0;right:0;background:none;border:none}.burger_burgerButton__3WOBX{padding:0.5rem !important;width:2.5rem !important;height:2.5rem !important}.burger_closeButton__UU7vW{width:2.5rem !important;height:2.5rem !important}.burger_links__2lFvW{display:flex;flex-direction:column;height:100vh;padding-left:2rem;overflow:hidden}.burger_links__2lFvW a{display:flex;margin-top:1.75rem;font-size:1.25rem;color:snow;text-decoration:none;-webkit-font-feature-settings:"c2sc";font-feature-settings:"c2sc";font-variant:small-caps;opacity:0.5}.burger_links__2lFvW a:hover{opacity:1}.burger_menu__1mG6a{margin:0;padding:0;background-color:#1f2020}.burger_body__1ZFLe{overflow:hidden}

.Name_name__2cbV1{display:flex;flex-direction:column;margin-left:10rem;position:absolute;-webkit-transform:translateY(-50%);transform:translateY(-50%)}.Name_name__2cbV1 .Name_title__17grI{font-family:"Oswald", sans-serif;font-size:7.5rem}.Name_name__2cbV1 .Name_title__17grI span{color:#f1c40f}.Name_name__2cbV1 .Name_subtitle__28Qce{opacity:0.75;font-size:2.5rem;padding-top:0.75rem;padding-left:0.75rem}@media (max-width: 600px){.Name_name__2cbV1{margin-left:2.5rem}.Name_name__2cbV1 .Name_title__17grI{font-size:3.5rem}.Name_name__2cbV1 .Name_subtitle__28Qce{font-size:1rem;padding-top:0.25rem;padding-left:0.5rem;line-height:normal}}

.Social_social__3e60m{position:fixed;bottom:0;right:0;z-index:99999}.Social_social__3e60m .Social_icon__25eIl{height:2rem;margin-right:1rem;margin-bottom:1rem;opacity:0.5}.Social_social__3e60m .Social_icon__25eIl:hover{opacity:1}

.Home_home__1X9H1{display:flex;height:100vh;width:100vw;background-image:url(/static/media/vinoth.733b11f8.webp),url(/static/media/vinoth.84e82230.png);background-repeat:no-repeat;background-position-y:center;background-position-x:right;background-origin:border-box;background-size:cover;-webkit-animation:Home_fadeIn__D8tqz 2s;animation:Home_fadeIn__D8tqz 2s}@media (max-width: 768px){.Home_home__1X9H1{background-size:cover;background-position:center}}@-webkit-keyframes Home_fadeIn__D8tqz{from{opacity:0}to{opacity:1}}@keyframes Home_fadeIn__D8tqz{from{opacity:0}to{opacity:1}}

.Loading_loaderWrapper__2dljD{height:100vh;width:100vw;display:flex;align-items:center;justify-content:center}.Loading_loaderWrapper__2dljD .Loading_loader__j925p{margin-right:1rem;-webkit-animation-name:Loading_pulse__38Anl;animation-name:Loading_pulse__38Anl;-webkit-animation-duration:1s;animation-duration:1s;-webkit-animation-direction:alternate;animation-direction:alternate;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite}@-webkit-keyframes Loading_pulse__38Anl{from{opacity:0.5;-webkit-transform:rotate(0);transform:rotate(0)}to{opacity:1;-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes Loading_pulse__38Anl{from{opacity:0.5;-webkit-transform:rotate(0);transform:rotate(0)}to{opacity:1;-webkit-transform:rotate(360deg);transform:rotate(360deg)}}

